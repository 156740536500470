  import React from 'react'

export default function AllService() {
  return (
    <>
    <div className='aboutHeader mt-5'>
    <div className="container d-flex justify-content-center align-items-center h-100" style={{margin:"auto"}}>
      <div>
        <h1 className='text-center textcolors' style={{ color: 'white', position: 'relative', }}>Services for Architectural, Civil, Structural, for Architects, Engineers, Builders, Consultants and General Contractors.</h1>
      </div>
    </div>
  </div>
  </>
  )
}
